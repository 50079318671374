<template>
  <h1>Notification</h1>
</template>

<script>
export default {

};
</script>

<style>

</style>
