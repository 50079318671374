import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=e2e73816&scoped=true&"
import script from "./NotFound.vue?vue&type=script&lang=js&"
export * from "./NotFound.vue?vue&type=script&lang=js&"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=e2e73816&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2e73816",
  null
  
)

export default component.exports