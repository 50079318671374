import axiosInstance from '@/config/axios';

export default {
  async SET_INTERACTIONS({ commit }, payload) {
    try {
      const { data } = await axiosInstance.post('/user_interactions/', payload);
      commit('setInteraction', data);
    } catch (error) {
      console.log(error);
    }
  },
  async GET_INTERACTIONS({ commit }, payload) {
    try {
      const response = await axiosInstance.get(`/user_interactions/?user=${payload}&size=10000`);
      const { results } = response.data;
      commit('setInteractions', results);
    } catch (error) {
      console.log(error);
    }
  },
  async DELETE_INTERACTION({ commit }, payload) {
    try {
      const response = await axiosInstance.delete(`/user_interactions/${payload}/`);
      commit('set_success', response);
    } catch (error) {
      console.log(error);
    }
  },
  setInternetStatus({ commit }, payload) {
    commit('setInternetStatus', payload);
  },
};
